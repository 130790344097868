import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { TitlePage } from '@styles/styles.global';

import {
  Container,
  Content,
  CompartmentContainer,
  Compartment,
  IconsRow,

  TitleRow,

} from './FooterStyle';

import LogoCLickDoBem from '@assets/Login/sociallis-logo-hor2.png';

import Logo from '../assets/logo.svg';

export default function Footer () {
  return (


          <Grid container alignItems="center" justify="center" style={{background: '#EDF1F5'}}>
              <Grid container  alignItems="center" justify="center" style={{paddingTop: 5}} >
                <p>Dúvidas e mais informações:</p>
                </Grid>
                <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                <p style={{alignItems:"center"}}><strong> Relacionamento com o doador (81) 3268-9255 </strong></p>
                </Grid>
                <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                <p><strong> WhatsApp (81) 98894-7876</strong></p>
                </Grid>
                <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                <p>Funcionamento: Segunda a Sexta-feira das 9h às 18h</p>
                </Grid>

                <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                <p><strong>Política de Privacidade</strong></p>
                </Grid>


            </Grid>


  );
}
